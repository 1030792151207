<template>
  <!-- 我的 -->
  <div class="incomeList">
    <div class="headarea">
      <div class="headarea-t">
        <h3>{{userInfo.balance + userInfo.zbalance || 0}}</h3>
        <p>累计收益</p>
      </div>
      <ul class="headarea-b">
        <li>
          <h3>{{userInfo.zbalance + 0 || 0}}</h3>
          <p>已获收益</p>
        </li>
        <p class="line"></p>
        <li>
          <h3>{{userInfo.balance + 0 || 0}}</h3>
          <p>账户余额</p>
        </li>
      </ul>
    </div>
    <van-list class="van-hairline--top" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-cell v-for="(item) in list" :key="item.id" :value="(item.type == 1 ? '-' : '+') + item.balance"
        :label="item.createTime" title-class="title-class"
        :value-class="{'value-class':true,'active':item.type == 0}" label-class="label-class">
        <template #title>
          <p class="custom-title">{{item.type == 1 ? "提现" : "打款"}}（<span
              class="second-title van-ellipsis">{{item.name}}</span>）</p>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
  export default {
    name: "incomeList",
    data() {
      return {
        loading: false,
        finished: false,
        pageNum: 1,
        pageSize: 15,
        list: [],
        userInfo: {}
      }
    },
    mounted() {
      this.getProfile()
    },
    methods: {
      getProfile() {
        this.$api.user.profile({}, res => {
          this.userInfo = res.data
        })
      },
      onLoad() {
        this.$api.my.incomeList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }, res => {
          if (res.code === 200) {
            if (res.rows.length === 0 || res.rows.length < this.pageSize) {
              this.finished = true
            }
            this.list = this.list.concat(res.rows)
            this.loading = false
            this.pageNum++
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .incomeList {
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff;
    padding: 30px 30px 96px;

    .headarea {
      width: 100%;
      height: 300px;
      background: url(~@/assets/img/my/incomebg.png) center top/cover no-repeat;
      padding-top: 40px;
      text-align: center;
      margin-bottom: 40px;

      .headarea-t {
        font-size: 48px;
        color: #FFFFFF;
        line-height: 64px;
        letter-spacing: 2px;
        font-weight: 100;
        margin-bottom: 40px;

        h3 {
          font-family: Bebas;
        }

        p {
          font-size: 24px;
          color: #EEEEEE;
          line-height: 34px;
          margin-top: 4px;
        }
      }

      .headarea-b {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .line {
          width: 2px;
          height: 68px;
          background: rgba(255, 255, 255, 0.24);
        }

        li {
          flex: 1;
          font-size: 40px;
          color: #FFFFFF;
          line-height: 52px;

          h3 {
            font-family: Bebas;
            margin-bottom: 2px;
          }

          p {
            font-size: 24px;
            color: #EEEEEE;
            line-height: 34px;
          }
        }
      }
    }
  }

  .title-class {
    font-size: 32px;
    color: #333333;
    line-height: 44px;
    white-space: nowrap;
  }

  .value-class {
    font-size: 32px;
    font-weight: 800;
    color: #333333;
    line-height: 44px;
  }

  .active {
    color: #E5B215;
  }

  .label-class {
    font-size: 28px;
    color: #999999;
    line-height: 40px;
    margin-top: auto;
  }

  .van-cell {
    height: 152px;
    padding: 30px 18px 0;
  }

  .van-cell::after {
    left: 0;
  }

  .custom-title {
    display: flex;
  }

  .second-title {
    display: block;
    max-width: 400px;
    font-size: 28px;
  }
</style>
